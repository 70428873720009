import React, { Component } from 'react';
import logo from './logo.svg';
import icon from './logo-vertical.svg';
import './App.css';

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            closed: true
        }
    }

    render(){
        return (
        <div className="App">
        <div className="background img-1"></div>
        <div className="logo"><img src={logo} alt="logo"/></div>
        <div className="center">
            <div className="column">
                <div className="container">
                    <div className="vertical-align-wrap">
                        <div className="vertical-align vertical-align--middle">
                            <div className="icon">
                                <img src={icon} alt="icon"/>
                                <h2>building the web for you</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="bottom-left">
            <div className="contact-mail"><a href="mailto:contact@codearth.pl">e-mail: contact@codearth.pl</a></div>
        </div>
        <div className="bottom-right">
            <div id="slider" className={this.state.closed ? 'slider closed' : 'slider'}><p>CODEARTH.PL<br />
                                                ul. Grunwaldzka 24<br />
                                                65-338 Zielona Góra<br />
                                                NIP 9291738205</p></div>
            <div className="link button"><p onClick={ () => this.setState({closed: !this.state.closed}) }>company information</p></div>
        </div>
    </div>
  );
  }
}

export default App;
